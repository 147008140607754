import { useState } from "react";
import { Col, Modal, Button, Dropdown, DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";


function WeekSelectModal({show, setShow, weeks, setWeekIdx, setwInfo}) {
    let buttonVarients = ['primary', 'info']
    const handleClose = () => setShow(false);
    const [selected, setSelected] = useState({});
    const handleSelect = (key, event) => {
        setSelected({ key, value: event.target.value });
      };
    console.log(weeks)
      return (
        <div>  
          <Modal 
            show={show} 
            onHide={handleClose} 
            keyboard={false} 
            backdrop="static">
            <Modal.Header>
              <Modal.Title>작업할 주차 선택</Modal.Title>
            </Modal.Header>
  
            <Modal.Body 
              style={{alignItems: 'center', display: "flex", justifyContent: "center"}}>

            
        
            <DropdownButton onSelect={handleSelect} title={(selected?.key || "asd") }>
            
            {weeks.map((week, idx)=>{
              return(
                <Dropdown.Item eventKey={week} onClick={(event) => {
                  setWeekIdx(idx);
                  setwInfo(week.split('_'));
                  handleClose()
                }}>
                  {week.split('_')[0]}년 {week.split('_')[1]}월 {week.split('_')[2]}주차
                </Dropdown.Item>
              )
            })}
              
              </DropdownButton>
            {/* {weeks.map((week, idx)=> {
                return (
                  <Button style={{margin:"3%"}} key={idx} variant={buttonVarients[idx]} onClick={()=>{
                    setWeekIdx(idx);
                    setwInfo(week.split('_'));
                    handleClose()
                  }}>
                    {week.split('_')[0]}년 {week.split('_')[1]}월 {week.split('_')[2]}주차
                  </Button>
                )
              })} */}
            </Modal.Body>
  
            <Modal.Footer>
  
            </Modal.Footer>
          </Modal>
        </div>
      );
  }

export default WeekSelectModal;
