import { Container, Row, Col, Card,  } from "react-bootstrap";
import RowDivider from "../../common/RowDivider";


function SelectedCardBodyTexts({data}){
    return (
      <Container>
        <Row className='card-body-row'>
          <Col className='card-body-col'>
            <Card.Text>
              ai_score
              <br/><br/>{data.ai_score}
            </Card.Text>
          </Col>
          <Col className='card-body-col'>
            <Card.Text>
                k/d/a
                <br/><br/>{data.k}/{data.d}/{data.a}
              </Card.Text>
          </Col>
          <Col className='card-body-col'>
            <Card.Text>
                ai_score 색상
                <br/><br/>{data.ai_score_color_code}
              </Card.Text>
          </Col>
        </Row>
        <RowDivider/>
        <Row className='card-body-row'>
          <Col className='card-body-col'>
            <Card.Text>
              펜타킬 경과 시간
              <br/><br/>{data.completion_time}초
            </Card.Text>
          </Col>
          <Col className='card-body-col'>
            <Card.Text>
              damage
              <br/><br/>{data.damage_contribution}%
              </Card.Text>
          </Col>
          <Col className='card-body-col'>
            <Card.Text>
              소환사명
              <br/><br/>{data.summoner_name}
            </Card.Text>
          </Col>
        </Row>
      </Container>
    )
  }
  
  export default SelectedCardBodyTexts;