import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import WeekSelectModal from "../Component/Common/WeekSelectModal";
import { Dropdown, DropdownButton, Col, Row } from "react-bootstrap";


function Processed() {
  const apiUrl = window.location.origin == "http://localhost:3000" ? "renew-dev" : "renew"
  // let { weekString } = useParams();
  // const navigate = useNavigate();

  const [resize, setResize] = useState(window.innerWidth);

  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let [youtubeUrl, setYoutubeUrl] = useState("")

  let [inputUrl, setInputUrl] = useState("");
  let [inputTitle, setInputTitle] = useState("");
  let [inputPostingDate, setInputPostingDate] = useState("");
  let [inputVideoIdx, setInputVideoIdx] = useState("");
  let [inputFakeVideoName, setInputFakeVideoName] = useState("");
  let [sourceList, setSourceList] = useState([]);
  let [filteredSourceList,setfilteredSourceList] = useState([]);

  let [weeks, setWeeks] = useState([]);
  let [wInfo, setwInfo] = useState([]); 
  
  // 주차 목록 호출 및 선택
  useEffect(() => {
    let week_url = "https://"+apiUrl+".deeplol.gg/video/weeks?limit=10"
    axios.get(week_url)
      .then((result) => {
        let data = result.data.weeks
        setWeeks(data);
        console.log("weeks",weeks)

      })
      .catch((e) => {
        alert("주차 정보 호출에 문제가 발생했습니다.")
      })
  },[])
  

  // 선택한 주차 데이터 
  let [show, setShow] = useState(true);
  let [week_idx, setWeekIdx] = useState();
  let [fakeNames, setFakeNames] = useState([]);
  let [fakeIdx, setFakeIdx] = useState(0);

  useEffect(() => {    
    if (weeks.length != 0 && weeks != undefined && week_idx < weeks.length) {
      let url = "https://"+apiUrl+".deeplol.gg/video/select-candidate?video_type=pentakill&selected_week="+weeks[week_idx]+"&is_elected=1&is_just_short=0"  
      console.log(url)
      axios.get(url)
        .then((result) => {
          let data = result.data.candidates
            if (data.length != 0){
              data.sort((a, b) => b.score - a.score)
              setSourceList(data)
            }
            else {
              alert('투표된 영상이 없습니다.')
              window.location.reload();
            }
        })
        .catch((e) => {
          alert("선정된 데이터 처리에 실패했습니다.")
        })
    }
  }, [weeks, show])


  useEffect(() => {
    if (weeks.length != 0 && weeks != undefined && week_idx < weeks.length) {
      let url = "https://"+apiUrl+".deeplol.gg/video/processed/select-fake-name?weeks="+weeks[week_idx]

      axios.get(url)
        .then((result) => {
          let fakeNameData = result.data.lists
          setFakeNames(fakeNameData)
        })
        .catch((e) => {
          alert("가제 처리에 실패했습니다.")
        })
    }
  },[weeks, show])

  const [selected, setSelected] = useState({});
  const handleSelect = (key, event) => {
      setSelected({ key, value: event.target.value });
    };

  return(
    <Row style={{padding: "5%"}}>
      {
        (weeks.length != 0  || week_idx != undefined || wInfo==[]) 
        ? <WeekSelectModal 
            show={show} 
            setShow={setShow} 
            weeks={weeks} 
            setWeekIdx={setWeekIdx} 
            setwInfo={setwInfo}
          />
        : null
      }

      {
        (wInfo.length == 0) 
        ? <h1></h1> 
        : <h1> {wInfo[0].slice(2,4)}년 {wInfo[1]}월 {wInfo[2]}주차 가공된 영상 </h1>
      }
      <h6>유튜브에 올라갈 가공된 영상입니다.</h6>
      <h6>타이틀 미입력시 선택한 가제로 업데이트 됩니다.</h6>
      
      <Row style={{marginTop:'1%',marginBottom:'1%'}}>
          <Dropdown onSelect={handleSelect}>
          <Dropdown.Toggle >
            {
              inputFakeVideoName? inputFakeVideoName:"선택된 가제 없음"
                // (selected?.key || "선택된 가제 없음")

            }
          <Dropdown.Menu>
          {fakeNames.map((data)=>{
            return (
              <Dropdown.Item eventKey={data.fake_video_title} onClick={()=>{
                setInputVideoIdx(data.video_idx); 
                setInputFakeVideoName(data.fake_video_title); 
                setFakeIdx(data.video_idx);
                let _filtered = sourceList.filter(source => source.video_idx==fakeIdx)
                console.log(_filtered)

                setfilteredSourceList(sourceList.filter(source => source.video_idx==fakeIdx));
                setYoutubeUrl(sourceList.filter(source => source.video_idx==fakeIdx)[0].elected_video_url);
              }}>
                {data.fake_video_title}
              </Dropdown.Item>
            )
          })}
          </Dropdown.Menu>
          </Dropdown.Toggle>
          </Dropdown>
      </Row>
      <Row>
        <InputGroup className="mb-3 mt-1">
            <Form.Control
              placeholder={"유튜브 url 입력"}
              aria-label={"유튜브 url 입력"}
              aria-describedby="basic-addon2"
              onChange={(e)=>{ 
                setInputUrl(e.target.value)
              }}
            />
            <Form.Control
              placeholder={inputFakeVideoName}
              aria-label={"타이틀 입력"}
              aria-describedby="basic-addon3"
              onChange={(e)=>{ 
                setInputTitle(e.target.value)
              }}
            />
            <Form.Control
              type="date"
              placeholder="due date"
              aria-label="due date"
              aria-describedby="basic-addon4"
              onChange={(e)=>{ 
                setInputPostingDate(e.target.value)
              }}
            />
          <Button 
            variant="outline-secondary" 
            id="button-addon1"
            onClick={() => {
              if (inputUrl == ""){
                alert("입력된 url이 없습니다.")
              }
              else {
                sourceList.filter(source => source.video_idx==fakeIdx).map((data, idx) => {
                  let url = "https://"+apiUrl+".deeplol.gg/video/update-youtube-url"
                  let offset = new Date().getTimezoneOffset() * 60000;
                  let updateTime = new Date(Date.now()-offset).toISOString().replace('T', ' ').replace(/\..*/, '');
                  let body = {
                    "video_path": data.video_path,
                    "youtube_url": inputUrl,
                    "video_title": (inputTitle=='')? inputFakeVideoName: inputTitle,
                    "upload_datetime": updateTime,
                    "posting_date": inputPostingDate,
                    "weeks": weeks[week_idx],
                    "video_idx": inputVideoIdx,
                    "fake_video_title": inputFakeVideoName
                  } 
                  console.log(body)
                  axios.put(url, body)
                    .then((result) => {
                      alert("url 갱신이 완료되었습니다.")
                    })
                    .catch((e) => {
                      alert("url 갱신에 실패했습니다.")
                    })
                })
              }
              window.location.reload();
            }}
          >
            저장
          </Button>
        </InputGroup>
      </Row>
      <h6> {youtubeUrl != "" ? "저장된 url: " +  youtubeUrl : "저장된 url이 없습니다."} </h6>
    </Row>
  )
}

export default Processed;