import { Card, Col, Row } from "react-bootstrap";

function DefaultCardBody({data}){
    return (
      <Row className='card-body-row'>
        <Col className='card-body-col'>
          <Card.Text>
            {data.champion_name}, 
            <br/><br/>{data.tier}
          </Card.Text>
        </Col>
        <Col className='card-body-col'>
          <Card.Text>
            pro/streamer 여부: 
            <br/><br/>
            {data.pro_streamer_status ? data.pro_streamer_status : "-"}
          </Card.Text>
        </Col>
        <Col className='card-body-col'>
          <Card.Text>
            pro/streamer 이름: 
            <br/><br/>
            {data.pro_streamer_name ? data.pro_streamer_name: "-"}
          </Card.Text>
        </Col> 
      </Row>
    )
  }

export default DefaultCardBody;
