import { useState } from "react";
import { Container } from "react-bootstrap";
import RowDivider from "../common/RowDivider";
import DefaultCardBody from "../common/Card/Body";
import CandidateDoubleButton from "./DoubleButton";

function CandidateCardBody({data, apiUrl}) {
    let [isElected, setIsElected] = useState(data.is_elected)
    let [isJustShort, setIsJustShort] = useState(data.is_just_short)
    return (
      <Container>
        <CandidateDoubleButton data={data} isElected={isElected} setIsElected={setIsElected} isJustShort={isJustShort} setIsJustShort={setIsJustShort} apiUrl={apiUrl}/>
        <RowDivider/>
        <DefaultCardBody data={data}/>
      </Container>
      
    )
  }

export default CandidateCardBody;