import { Card, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import axios from "axios";

function CandidateCardFooter(videoScore, setInputValue, inputValue, setVideoScore, apiUrl, source){
    return (
      <Card.Footer>
            <Row>
              <Col>
                <InputGroup className="mb-3 mt-1">
                  <InputGroup.Text>{"점수: "+ videoScore+"점"}</InputGroup.Text>
                    <Form.Control
                      placeholder={"수정할 점수 입력"}
                      aria-label={"수정할 점수 입력"}
                      aria-describedby="basic-addon2"
                      onChange={(e)=>{ 
                        setInputValue(e.target.value)
                      }}
                    />
                  <Button 
                    variant="outline-secondary" 
                    id="button-addon1"
                    onClick={() => {
                      if (inputValue.replace(/[^0-9]/g, '') != "") {
                        setVideoScore(inputValue)
                        let url = "https://"+apiUrl+".deeplol.gg/video/update-candidate-score"
                        let body = {
                          "video_path": source,
                          "score": inputValue
                        }  
                        axios.put(url, body)
                          .then((result) => {
                            alert("스코어가 갱신되었습니다.")
                          })
                          .catch((e) => {
                            alert("스코어 갱신에 실패했습니다.")
                          })
                      }
                      else{
                        alert("숫자만 입력가능합니다.")
                      }
                    }}
                  >
                    수정
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Card.Footer>
    )
  }

export default CandidateCardFooter;