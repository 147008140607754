import React, {  useState, useEffect } from 'react';
import { InputGroup, Form, Button,Modal } from 'react-bootstrap';

function MoveRankButton({sourceList}) {
  const [showButton, setShowButton] = useState(false);

  const moveScroll = (scrollId)=> {
    const violation = document.getElementById(scrollId);
    if (violation == null){
      alert('해당 순위가 존재하지 않습니다. 범위 1~'+sourceList.length)
    }
    else {
      window.scrollTo({
        top: violation.offsetTop,
        behavior:"smooth"
      })
    } 
  }
  let [show, setShow] = useState(false);
  let [scrollId, setScrollId] = useState('');

  const handleClose = () => {
    if (show == true){
        setShow(false);
    }
    else {
        setShow(true)
    }
  }

  const showModal = ()=>{
    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>입력한 순위로 이동</Modal.Title>
                </Modal.Header>

                <Modal.Body style={{alignItems:'center', display:"flex", justifyContent:"center"}}>
                    <InputGroup className="mb-3" style={{paddingLeft:"20%",paddingRight:"20%"}}>
                    <Form.Control
                        style={{justifyContent:'center'}}
                        placeholder="순위 입력"
                        aria-label="video-rank"
                        aria-describedby="basic-addon1"
                        onChange={ (event) => {setScrollId(event.target.value);} }
                    />
                    <Button onClick={()=>{
                        setShow(false)
                        moveScroll(scrollId)
                    }}>Go</Button> 
                    </InputGroup> 
                </Modal.Body>
                <Modal.Footer/>
            </Modal>
        </div>
    )
  }

  useEffect(() => {
      const handleShowButton = () => {
          if (window.scrollY > 500) {
              setShowButton(true)
          } else {
              setShowButton(false)
          }
      }

      console.log(window.scrollY)
      window.addEventListener("scroll", handleShowButton)
      return () => {
          window.removeEventListener("scroll", handleShowButton)
      }
  }, [])

  return showButton && (
      <div className="move_rank__container">
        <button id="top" onClick={()=>{setShow(true)}} type="button" > 이동</button>
        {show == true? showModal():null}
      </div>

  )
}

export default MoveRankButton;