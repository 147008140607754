import { useState, useEffect } from "react";

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

import DefaultCardHeader from "./common/Card/Header";
import CandidateCardBody from "./candidate/Body";
import SelectedCardBody from "./selected/Card/Body";
import CandidateCardFooter from "./candidate/Footer";

function VideoComponent({ fake_name, key,source, score, rank, selected, data, processed, page_type }) {
  const apiUrl = window.location.origin == "http://localhost:3000" ? "renew-dev" : "renew"
  const [resize, setResize] = useState(window.innerWidth);
  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let [inputValue, setInputValue] = useState("");
  let [videoScore, setVideoScore] = useState(score);

  let mp4Source = processed ? source : "https://dtube.deeplol.gg/mp4/" + source + ".mp4"
  let webmSource = processed ? source : "https://dtube.deeplol.gg/webm/" + source.replace("mp4", "webm") + ".webm"   
  
  let [memo, setMemo] = useState(data.memo);
  return(
    <Col id={rank} sm={resize >= 1080 && !processed ? 4 : 12} md={resize >= 1080 && !processed ? 4 : 12} className={"mt-3"}>
      <Card border="light" bg="light">
        <Card.Header>
          <DefaultCardHeader rank={rank} data={data}/>
        </Card.Header>
        <Card.Body>
          <video src={page_type == "candidate" ? webmSource:mp4Source} width="100%" height="100%" controls/>
          {page_type == 'candidate'? <CandidateCardBody data={data} apiUrl={apiUrl}/>: null}
          {page_type == 'selected' ? <SelectedCardBody key={key} fake_name={fake_name} data={data} memo={memo} setMemo={setMemo} apiUrl={apiUrl}/> : null}
        </Card.Body>
        {page_type == "candidate" ? CandidateCardFooter(videoScore, setInputValue, inputValue, setVideoScore, apiUrl, source) : null }
      </Card>
    </Col>
  )
}

export default VideoComponent;