import { Row, Col, Button } from "react-bootstrap"

function DefaultCardHeader({rank, data}){
    return (
      <Row>
        <Col sm={9} style={{flex:"1"}}>
          <h6 style={{marginTop:'3%'}}>{rank}번</h6>
        </Col>
        <Col sm={3}>
          {data.is_elected == 1 ? <Button variant='dark'>Top 10 선정</Button> : null}
          {data.is_just_short == 1 ? <Button variant='warning'>쇼츠만</Button>: null}
        </Col>
      </Row>
    )
  }

export default DefaultCardHeader;