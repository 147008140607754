import { Container} from "react-bootstrap";
import DefaultCardBody from "../../common/Card/Body";
import RowDivider from "../../common/RowDivider";
import SelectedCardMemo from "./Memo";
import SelectedCardBodyTexts from "./Texts";
import VideoNameSelector from "./VideoNameSelector";

function SelectedCardBody({key, fake_name, data, memo, setMemo, apiUrl}){

  return (
      <Container>
        <VideoNameSelector data={data} fake_name={fake_name} apiUrl={apiUrl}/>
        <SelectedCardMemo data={data} memo={memo} setMemo={setMemo} apiUrl={apiUrl}/>
        <DefaultCardBody data={data}/>
        <RowDivider/>
        <SelectedCardBodyTexts data={data}/>
      </Container>
    )
  }

export default SelectedCardBody;