import { Container, Dropdown, DropdownButton } from "react-bootstrap";
import axios from "axios";
import { useState } from "react";

function VideoNameSelector({data, fake_name, apiUrl}){
    const [selected, setSelected] = useState({});
    const handleSelect = (key, event) => {
        setSelected({ key, value: event.target.value });
      };
    
    return (
        <Container>
        <DropdownButton
        onSelect={handleSelect}
            title={
                ((data.video_idx) == 0 )
                ? (selected?.key || "선택된 가제 없음")
                : (selected?.key || fake_name[data.video_idx-1])
            }
        >
            {
                fake_name.map((d, idx)=>{
                    return(
                        <Dropdown.Item eventKey={d} onClick={
                            (event) => {
                                console.log(idx)

                                let url = "https://"+apiUrl+".deeplol.gg/video/selected/update-video-idx"
                                let body = {
                                "video_idx": idx+1,
                                "video_path": data.video_path
                                }
                                axios.put(url, body)
                                .then((result) => {
                                    ''
                                })
                                .catch((e) => {
                                alert("가제 업데이트 실패.")
                                })
                            }
                        }>{d}</Dropdown.Item>
                    )
                })
            }
        </DropdownButton>
        </Container>
    )
}

export default VideoNameSelector;