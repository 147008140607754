import axios from "axios"
import { Button, Row, Col } from "react-bootstrap"

function reverseValue(value){
    return (
      value == 0 ? 1: 0
    )
  }
  
function CandidateDoubleButton({data, isElected, setIsElected, isJustShort, setIsJustShort, apiUrl}){
    let buttonStyle = {width:"100%"}
    return (
        <Row>
        <Col>
            <Button style={buttonStyle} variant='dark'
            onClick={() => {
                let reversedIsElected = reverseValue(isElected)
                setIsElected(reversedIsElected)

                let url = "https://"+apiUrl+".deeplol.gg/video/candidate/update-elected"
                let body = {
                    "video_path": data.video_path,
                    "is_elected": reversedIsElected
                }  
                axios.put(url, body)
                    .then((result) => {
                    if (reversedIsElected == 0) {
                        alert("선정 해제 되었습니다");
                    }
                    else {
                        alert("선정 되었습니다");
                    }
                    })
                    .catch((e) => {
                    alert("스코어 갱신에 실패했습니다.")
                    })
                }}
            >
            Top 10 선정 선택
            </Button>
        </Col>
        <Col>
        <Button style={buttonStyle} variant='warning'
            onClick={() => {
                let reversedIsJustShort = reverseValue(isJustShort)
                setIsElected(reversedIsJustShort)

                let url = "https://"+apiUrl+".deeplol.gg/video/candidate/update-just-short"
                let body = {
                "video_path": data.video_path,
                "is_just_short": reversedIsJustShort
                }  
                axios.put(url, body)
                .then((result) => {
                    if (reversedIsJustShort == 0) {
                    alert("쇼츠만으로 올라기지 않게 되었습니다");
                    }
                    else {
                    alert("쇼츠만 선택하도록 되었습니다");
                    }
                })
                .catch((e) => {
                    alert("스코어 갱신에 실패했습니다.")
                })
                }}
            >
            쇼츠만 선택
            </Button>
        </Col>
        </Row>
    )
    }

export default CandidateDoubleButton;