import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import VideoComponent from "../Component/Video/VideoComponent";
import WeekSelectModal from "../Component/Common/WeekSelectModal";
import MoveRankButton from "../Component/Video/candidate/MoveRankButton"
import { Col } from "react-bootstrap";


function Candidate() {
  const apiUrl = window.location.origin == "http://localhost:3000" ? "renew-dev" : "renew"

  const [resize, setResize] = useState(window.innerWidth);
  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  let [sourceList, setSourceList] = useState([]);
  let [weeks, setWeeks] = useState([]);
  let [wInfo, setwInfo] = useState([]);
  let [show, setShow] = useState(true);
  let [week_idx, setWeekIdx] = useState();  
  let [stringConcat, setStringConcat] = useState('');

  useEffect(() => {
    let week_url = "https://"+apiUrl+".deeplol.gg/video/weeks?limit=10"
    axios.get(week_url)
      .then((result) => {
        let data = result.data.weeks
        setWeeks(data);
      })
      .catch((e) => {
        alert("데이터를 로드에 실패했습니다.")
      })
  },[])

  useEffect(() => {
    if (weeks.length != 0 && weeks != undefined && week_idx < weeks.length) {
      let url = "https://"+apiUrl+".deeplol.gg/video/select-candidate?video_type=pentakill&selected_week="+weeks[week_idx]+"&is_elected=0&is_just_short=0"  
      setwInfo(weeks[week_idx].split('_'));
      axios.get(url)
        .then((result) => {
          let data = result.data.candidates
          if (data.length != 0){
            data.sort((a, b) => b.score - a.score)
          }
          setSourceList(data)
          setStringConcat(data.map((_data, rank) => _data.champion_name+"("+(rank+1)+")" ).join(''))
          console.log(stringConcat)
        })
        .catch((e) => {
          alert("데이터를 로드에 실패했습니다.")
        })
    }
  }, [weeks, show])

  sourceList.sort((a, b) => b.score - a.score)

  const handleCopyClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(stringConcat);
      alert('링크가 복사되었습니다.');
    } catch (error) {
      alert('링크 복사에 실패하였습니다.');
    }
  };

  return(
    <>
      <Row style={{padding: "5%"}} >
        {(weeks.length != 0  || week_idx != undefined || wInfo==[]) 
          ? <WeekSelectModal 
              show={show} 
              setShow={setShow} 
              weeks={weeks} 
              setWeekIdx={setWeekIdx} 
              setwInfo={setwInfo}/>
          : null}

        <Row>
            {(wInfo.length == 0) 
              ? <h1>년 월 주차 가공된 영상</h1> 
              : <h1> {wInfo[0].slice(2,4)}년 {wInfo[1]}월 {wInfo[2]}주차 가공된 영상 </h1>}
            <h6>수정된 점수로 정렬하려면 새로고침 버튼을 누르세요.</h6>
        </Row>

        <Row>
          <Col>
            <Button 
              className="candidate_title_double_button" style={{padding:"10px", marginRight:"5px", marginLeft:"5px"}} variant="outline-secondary" onClick={() => {window.location.reload();} }>새로고침</Button>
            <Button 
              className="candidate_title_double_button" style={{padding:"10px", marginRight:"10px"}} onClick={()=>{
              handleCopyClipBoard()
            }}>순위 텍스트 복사</Button>
          </Col>
        </Row>

        {
          sourceList.map((data, idx) => {
            return (
              <VideoComponent id="123" key={idx} source={data.video_path} score={data.score} rank={idx+1} selected={false} data={data} page_type='candidate' />
            )})
        }
        
      </Row>
      <MoveRankButton length={sourceList}/>
    </>
  )
}

export default Candidate;