import { InputGroup, FloatingLabel, Form, Button } from "react-bootstrap";
import axios from "axios";

function SelectedCardMemo({memo, setMemo, data, apiUrl}){
    return (
      <InputGroup>
        <FloatingLabel controlId="floatingTextarea2" label="해당 영상 메모">
          <Form.Control
            as="textarea"
            placeholder="Leave a comment here"
            value={memo}
            style={{ height: '150px' }}
            onChange={(e)=>{
              setMemo(e.target.value)
              console.log(memo);
            }}
          />
          <Button style={{width:"100%"}} variant="outline-secondary" onClick={()=>{
            let url = "https://"+apiUrl+".deeplol.gg/video/memo/update"
            let body = {
              "memo": memo,
              "video_path": data.video_path
            }
            axios.put(url, body)
            .then((result) => {
              alert("해당 메모가 업데이트었습니다.")
            })
            .catch((e) => {
              alert("스코어 갱신에 실패했습니다.")
            })
          }}>저장</Button>
        </FloatingLabel>
      </InputGroup>
    )
    
  }

  export default SelectedCardMemo;