import { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import axios from 'axios';



function FakeVideoNameInputModal({
    fakeVideoName, 
    setFakeVideoName,
    fakeVideoNameModalShow, 
    setFakeVideoNameModalShow,
    apiUrl,
    week,
    update,
    video_idx
    }) {
    const [newFakeVideoName, setNewFakeVideoName] = useState('');
    const handleClose = () => setFakeVideoNameModalShow(false);
    const insertFakeName = ()=> {
        console.log(week,newFakeVideoName, fakeVideoName.length+1)
        if (newFakeVideoName != ''){
            setFakeVideoName([... fakeVideoName, newFakeVideoName]);
            setFakeVideoNameModalShow(false);

            let url = "https://"+apiUrl+".deeplol.gg/video/selected/insert-fake-name"
            let body = {
                "weeks": week,
                "fake_video_name":newFakeVideoName,
                "video_idx": fakeVideoName.length + 1 
            }  
            
            axios.post(url, body)
            .then((result) => {
                ''
            })
            .catch((e) => {
                alert("데이터를 로드에 실패했습니다.")
            })
        }
        handleClose();
    }

    

    return (
        <div>
            <Modal show={fakeVideoNameModalShow} onHide={handleClose}>
                <Modal.Header>
                    생성할 가제 이름 입력
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="fakeVideoNameForm">
                        <Form.Control 
                            type="text" 
                            onChange={
                                (event) => {
                                    console.log(event.target.value)
                                    setNewFakeVideoName(event.target.value);
                                }
                            }
                        />
                    </Form.Group>
                </Modal.Body>
                <Button onClick={insertFakeName}>
                    생성
                </Button>
            </Modal>
        </div>
    );
}

export default FakeVideoNameInputModal;