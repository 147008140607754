import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

function Main() {
  return(
    <Row style={{padding: "5%"}}>
      <h1>펜타킬 영상 선정용 페이지입니다.</h1>
      <Row>
        <Card border="warning" style={{ width: '100%', margin: "5px", padding: "5px"}}>
          <Card.Header>모바일앱 사용 방법</Card.Header>
          <Card.Body>
            <Card.Text>
              크롬 브라우저로 pentakill.deeplol.gg에 들어갑니다.
            </Card.Text>
            <Card.Text>
              우측 상단 점 세개 아이콘을 누른 뒤 "홈 화면에 추가" 버튼을 누릅니다.
            </Card.Text>
            <Card.Text>
              잠시 후 설치가 완료되며 앱처럼 사용가능합니다.
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
      <Row>
        <Card border="warning" style={{ width: '100%', margin: "5px", padding: "5px"}}>
          <Card.Header>영상 선정할 때 사용 방법</Card.Header>
          <Card.Body>
            <Card.Text>
              메뉴 중 "후보 영상"을 클릭하여 페이지를 이동하면 이번주에 선정해야할 후보 영상 목록이 보여집니다. 
            </Card.Text>
            <Card.Text>
              영상을 보시고 점수 입력 후 수정 버튼을 누르시면 점수가 저장됩니다.
            </Card.Text>
            <Card.Text>
              중간중간 새로고침을 하며 점수순서대로 정렬할 수 있습니다.
            </Card.Text>
            <Card.Text>
              모든 영상을 확인하셨으면 메뉴에서 "선정된 영상"을 클릭하여 어떤 영상이 선정되었는지 다시 확인합니다.
            </Card.Text>
            {/* <Card.Text>
              선정된 영상은 점수로 정렬되며 자동으로 10개가 추려집니다.
            </Card.Text> */}
          </Card.Body>
        </Card>
      </Row>
      <Row>
        <Card border="warning" style={{ width: '100%', margin: "5px", padding: "5px" }}>
          <Card.Header> 선정된 영상 링크가 필요할 때 사용 방법</Card.Header>
          <Card.Body>
            <Card.Text>
              메뉴에서 "선정된 영상"을 클릭하여 페이지를 이동합니다.
            </Card.Text>
            <Card.Text>
              "원본 데이터 링크 복사 버튼" 텍스트 옆 복사 버튼을 누르면 영상의 나스 주소가 클립보드에 복사됩니다.
            </Card.Text>
            <Card.Text>
              메모장이나 검색창에 붙여넣기하시면 확인하실 수 있습니다.
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
      <Row>
        <Card border="warning" style={{ width: '100%', margin: "5px", padding: "5px" }}>
          <Card.Header> 유튜브에 영상 업로드 후 사용 방법 </Card.Header>
          <Card.Body>
            <Card.Text>
              메뉴에서 "가공된 영상"을 클릭하여 페이지를 이동합니다.
            </Card.Text>
            <Card.Text>
              "유튜브 url 입력"이라고 표기되어 있는 입력칸에 유튜브 url을 입력 후 저장 버튼을 누릅니다.
            </Card.Text>
            <Card.Text>
              성공적으로 저장되면 입력칸 아래 "저장된 url: 입력한 url"로 표시됩니다.
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
    </Row>
  )
}

export default Main;