import './App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import jwtDecode from 'jwt-decode'
import { Routes, Route, useNavigate } from 'react-router-dom'
import React, { Suspense, useEffect } from 'react';

import Loader from './Component/Common/Loader';
import TopButton from './Component/TopButton'

import Main from './Page/Main';
import Login from './Page/Login';
import Candidate from './Page/Candidate';
import Selected from './Page/Selected';
import Processed from './Page/Processed';


function App() {
  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem("token")
    if (token == undefined) {
      navigate('/login')
    }
    else {
      let decoded = jwtDecode(token);
      let check_email = process.env.REACT_APP_EMAIL
      if (decoded.email != check_email) {
        navigate('/login');
      }
    }
  }, [])

  return (
    <div className="App">
      <NavBar />
      <Suspense 
        fallback={
          <Loader type="spokes" color="#E5FFCC" message="로딩중입니다" />
        }>
        <Routes>
          <Route path='/' element={
            <Main />
          }>
          </Route>
          <Route path='/candidate/' element={
            <Candidate/>
          } />
          <Route path='/selected/' element={
            <Selected/>
          } />

          <Route path='/processed/' element={
            <Processed/>
          } />
          <Route path='/login' element={
            <Login/>
          } />
        </Routes>
      </Suspense>
      <TopButton></TopButton>
    </div>
  );
}


function NavBar() {
  return (
    <Navbar bg="dark" expand="lg" variant="dark">
      <Container>
        <Navbar.Brand href="/">
          <img src='https://www.deeplol.gg/images/deeplol_logo.svg'></img>
          펜타킬</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/candidate">후보 영상</Nav.Link>
            <Nav.Link href="/selected">선정된 영상</Nav.Link>
            <Nav.Link href="/processed">가공된 영상</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Nav className="me-auto">
            <Nav.Link href='/login'> 로그인</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}


export default App;
