import React, { useState, useEffect } from 'react';
import axios from 'axios';

import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
}
from 'mdb-react-ui-kit';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
// import jwt from 'jsonwebtoken';
import jwtDecode from 'jwt-decode'


function Login() {
  const apiUrl = window.location.origin == "http://localhost:3000" ? "renew-dev" : "renew"

  let [inputId, setInputId] = useState('');
  let [inputPwd, setInputPwd] = useState('');
  const navigate = useNavigate();
  let [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("token")
    if (token != undefined) {
      let decoded = jwtDecode(token);
      let check_email = process.env.REACT_APP_EMAIL
      if (decoded.email == check_email) {
        setIsLogin(true);
      }
    }
  }, [])
  if (isLogin){
    return (
      <MDBContainer fluid>

        <MDBRow className='d-flex justify-content-center align-items-center h-100'>
          <MDBCol col='12'>

            <MDBCard className='bg-dark text-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '400px'}}>
              <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

                <h2 className="fw-bold mb-2 text-uppercase">My Page</h2>

                <Button 
                  variant="outline-secondary" 
                  id="button-addon1"
                  onClick={() => {
                    localStorage.removeItem("token")
                    setIsLogin(false)
                  }}
                >
                  로그아웃
                </Button>
              </MDBCardBody>
            </MDBCard>

          </MDBCol>
        </MDBRow>

      </MDBContainer>
    );
  }
  else {
    return (
      <MDBContainer fluid>

        <MDBRow className='d-flex justify-content-center align-items-center h-100'>
          <MDBCol col='12'>

            <MDBCard className='bg-dark text-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '400px'}}>
              <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

                <h2 className="fw-bold mb-2 text-uppercase">Login</h2>

                <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' label='Email address' id='formControlLg' type='email' size="lg" 
                  onChange={(e)=>{ 
                    setInputId(e.target.value)
                  }}
                />
                <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' label='Password' id='formControlLg' type='password' size="lg"
                  onChange={(e)=>{ 
                    setInputPwd(e.target.value)
                  }}
                />

                <Button 
                  variant="outline-secondary" 
                  id="button-addon1"
                  onClick={() => {
                    let url = "https://"+apiUrl+".deeplol.gg/video/login?email="+inputId+"&pwd="+inputPwd
                
                    axios.get(url)
                      .then((result) => {
                        let token = result.data
                        let decoded = jwtDecode(token);
                        let check_email = process.env.REACT_APP_EMAIL
                        if (decoded.email == check_email) {
                          localStorage.setItem('token', token)
                          navigate('/');
                        }
                        else{
                          alert("등록되지 않은 사용자입니다.")
                        }
                      })
                      .catch((e) => {
                        alert("이메일 또는 비밀번호를 확인해주세요.")
                      })
                  }}
                >
                  로그인
                </Button>
              </MDBCardBody>
            </MDBCard>

          </MDBCol>
        </MDBRow>

      </MDBContainer>
    );
  }
}

export default Login;