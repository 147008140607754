import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import VideoComponent from "../Component/Video/VideoComponent";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Col, Container, DropdownButton, ListGroup, ListGroupItem } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import WeekSelectModal from "../Component/Common/WeekSelectModal";

import { Alert } from "bootstrap";
import FakeVideoNameInputModal from "../Component/Common/fakeVideoNameInputModal";

import Dropdown from "react-bootstrap/Dropdown";
import FakeNameOption from "../Component/Video/selected/fakeNameOption";

function Selected() {
  const apiUrl = window.location.origin == "http://localhost:3000" ? "renew-dev" : "renew"
  let { weekString } = useParams();
  const navigate = useNavigate();

  const [resize, setResize] = useState(window.innerWidth);
  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let [sourceList, setSourceList] = useState([]);
  let [weeks, setWeeks] = useState([]);
  
  let [wInfo, setwInfo] = useState([]);
  let [weekMemo, setWeekMemo] = useState("");
  let [show, setShow] = useState(true);
  let [week_idx, setWeekIdx] = useState();

  let [fakeVideoName, setFakeVideoName] = useState([]);
  let [fakeVideoNameModalShow, setFakeVideoNameModalShow] = useState(false);

  useEffect(() => {
    let week_url = "https://"+apiUrl+".deeplol.gg/video/weeks?limit=10"

    axios.get(week_url)
      .then((result) => {
        let data = result.data.weeks
        setWeeks(data);
      })
      .catch((e) => {
        alert("데이터를 로드에 실패했습니다.")
      })
  },[])

  
  
  useEffect(() => {
    console.log(weeks);
    if (weeks.length != 0 && weeks != undefined && week_idx < weeks.length) {
      let url = "https://"+apiUrl+".deeplol.gg/video/select-candidate?video_type=pentakill&selected_week="+weeks[week_idx]+"&is_elected=1&is_just_short=1"  
      console.log("data:"+url)
      axios.get(url)
        .then((result) => {
          let data = result.data.candidates
            if (data.length != 0){
              data.sort((a, b) => b.score - a.score)
            }
          setSourceList(data)
        })
        .catch((e) => {
          alert("데이터를 로드에 실패했습니다.")
        })
    }
    
  }, [weeks, show])

  useEffect(() => {
    if (weeks.length != 0 && weeks != undefined && week_idx < weeks.length) {
      let url = "https://"+apiUrl+".deeplol.gg/video/week-memo/select?weeks="+weeks[week_idx]      
      console.log("memo:"+url)
      axios.get(url)
        .then((result) => {
          setWeekMemo(result.data.memo)
        })
        .catch((e) => {
          setWeekMemo('')
        })
    }
  }, [weeks, show])

  useEffect(() => {
    if (weeks.length != 0 && weeks != undefined && week_idx < weeks.length) {
    let week_url = "https://"+apiUrl+".deeplol.gg/video/selected/select-fake-name?week="+weeks[week_idx]

    axios.get(week_url)
      .then((result) => {
        let data = result.data.names
        setFakeVideoName(data);
      })
      .catch((e) => {
        alert("데이터를 로드에 실패했습니다.")
      })
    }
  }, [weeks, show])


  

  return(
    <Row style={{padding: "3%"}}>
      {
        (weeks.length != 0 || weeks != undefined || week_idx == weeks.length || wInfo==[]) 
        ? <WeekSelectModal 
            show={show} 
            setShow={setShow} 
            weeks={weeks} 
            setWeekIdx={setWeekIdx} 
            setwInfo={setwInfo}
          />
        : null
      }
      {
        (fakeVideoNameModalShow==true) 
        ? <FakeVideoNameInputModal 
            fakeVideoName={fakeVideoName}
            setFakeVideoName={setFakeVideoName}
            fakeVideoNameModalShow={fakeVideoNameModalShow}
            setFakeVideoNameModalShow={setFakeVideoNameModalShow}
            apiUrl={apiUrl}
            week={weeks[week_idx]}
          />
        : null
      }

      <Row>
        <Col sm={5}>
          <Row>
            <br/>
            <br/>
            <br/>
            {
              (wInfo.length == 0) 
              ? <h1>년 월 주차 가공된 영상</h1> 
              : <h1> 
                {wInfo[0].slice(2,4)}년 {wInfo[1]}월 {wInfo[2]}주차 가공된 영상 
                </h1>
            }
          </Row>
          <Row>
            <Col><b>생성된 영상 가제 목록</b></Col>
            <ListGroup as='ol' numbered>

              {
                fakeVideoName.map((data, idx) => {
                  return (
                    <Row>
                      <Col sm='11'>
                          <ListGroup.Item as='li'>{data}</ListGroup.Item>
                      </Col>
                      <Col sm='1'>
                      <Dropdown as={"btn-grp"+idx}>
                          <Dropdown.Toggle />
                          <Dropdown.Menu>
                              <Dropdown.Item onClick={
                                () => {
                                  console.log(weeks[week_idx], idx+1);
                                  let url = "https://"+apiUrl+".deeplol.gg/video/selected/delete-fake-name"
                                  let body = {
                                    "weeks": weeks[week_idx],
                                    "video_idx": idx+1
                                  }
                                  console.log(body);
                                  axios.put(url, body)
                                  .then((result) => {
                                    console.log(fakeVideoName)
                                    alert('가제가 삭제 되었습니다.')
                                    window.location.reload();

                                })
                                .catch((e) => {
                                    alert("가제 삭제에 실패했습니다.")
                                })
                                }
                              }>
                                  삭제
                              </Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>
                      </Col>
                    </Row>
                  )
                })
              }
              <ListGroupItem 
                action active 
                onClick={()=>{setFakeVideoNameModalShow(true)}}>
                영상 가제 추가
              </ListGroupItem>
            </ListGroup>
            
          </Row>
        </Col>
        <Col sm={7} >
          <FloatingLabel controlId="floatingTextarea2" label={wInfo[0]+"년 "+wInfo[1]+"월 "+wInfo[2]+"주차 요청사항 입력"}>
              <Form.Control
                as="textarea"
                placeholder="Leave a comment here"
                value={weekMemo}
                style={{ height: '200px' }}
                onChange={(e)=>{
                  setWeekMemo(e.target.value)
                }}
              />
              <Button style={{width:"100%"}} variant="outline-secondary" onClick={()=>{
                let url = "https://"+apiUrl+".deeplol.gg/video/week-memo/update"
                let body = {
                  "memo": weekMemo,
                  "weeks": weeks[week_idx]
                }
                axios.put(url, body)
                .then((result) => {
                  alert("해당 메모가 업데이트었습니다.")
                })
                .catch((e) => {
                  alert("스코어 갱신에 실패했습니다.")
                })
              }}>저장</Button>
          </FloatingLabel>
        </Col>
      </Row>
      <Row style={{padding: "3%"}}>
      <hr style={{color:'gray', backgroundColor:"darkgray", height:'1.5px'}}/>
        {
          sourceList.map((data, idx) => {
            return(
              <VideoComponent fake_name={fakeVideoName} key={idx} source={data.video_path} score={data.score} rank={idx+1} selected={true} data={data} page_type='selected'/>
            )
          })
        }
      </Row>
    </Row>
  )
}

export default Selected;