function RowDivider({}) {
    return (
      <hr style={{
        background: 'gray',
        color: 'gray',
        borderColor: 'gray',
        height: '1px',
      }}/>
    )
  }

export default RowDivider;